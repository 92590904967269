import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import BlogPostsSection from "../components/sections/_blog-posts-section";
import ogimage from "../images/ogimages/blog.png";

export default function BlogPage({data}) {
    const blogPosts = data.allContentfulBlogPost.edges;
    const categories = data.allContentfulBlogPostCategory.edges;
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Blog',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Blog',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Blog</title>
            </Helmet>

            <div className={'blog'}>
                <BlogPostsSection categories={categories} blogPosts={blogPosts} activeCategory={`all`}/>
            </div>
        </Layout>
    );
}


export const query = graphql`
    query {
        contentfulPage(id: {eq: "3300695f-b96e-5305-ad97-53b807a79025"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
        allContentfulBlogPostCategory(filter: {slug: {ne: "all"}, isEconomyCategory: {ne: true}}) {
            edges {
                node {
                    id
                    title
                    slug
                    isEconomyCategory
                }
            }
        }
        allContentfulBlogPost(sort: {fields: publishedAt, order: DESC}) {
            edges {
                node {
                    id
                    title
                    slug
                    image {
                        title
                        url
                    }
                    author
                    publishedAt(formatString: "MMMM D, Y")
                    tags
                    category {
                        title
                        slug
                    }
                    body { raw }
                    excerpt { raw }
                }
            }
        }
    }
`;
